<mat-card class="login-form center-block">
    <form class="container-fluid grid grid-pad">

        <div class="error-message col-sm-12" *ngIf="!!errorMessage">
            <span{{ errorMessage }}
        </div>
        <div class="info-message col-sm-12" *ngIf="!!infoMessage">
            {{ infoMessage }}
        </div>

        <div *ngIf="!completed">
            <div class="commentary">No worries! We'll send you an email to reset your password.</div>

            <div class="col-sm-12">
                <mat-form-field class="full-size col-sm-12">
                    <input matInput  class="col-sm-12"
                        id="recoveryEmail"
                        [(ngModel)]="email"
                        (change)="validateEmail(true)"
                        (keyup)="validateEmail(false)"
                        placeholder="Email address"
                        type="email"
                        name="email" />
                </mat-form-field>
                <mat-error *ngIf="emailErrorMessage">
		            {{ emailErrorMessage }}
	            </mat-error>
            </div>

            <br />
            <div class="col-sm-12 accept-cancel">
                <button color="primary"
                    [disabled]="!validEmail"
                    (click)="resetPassword()"
                    mat-button mat-raised-button>
                    Request reset
                </button>
            </div>
        </div>
    </form>
</mat-card>
