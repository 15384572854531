<div class="token-creation-screen screen">
    <h2>Generating new authorization</h2>

    <div class="scopes">
        <div class="explanation">
            The owner of this token will have permissions to:
        </div>
        <ul class="scope-list">
            <li *ngFor="let scope of scopes">
                <span class="name">{{ scope_name(scope) }}</span>
                <span class="description">{{ scope_description(scope) }}</span>
            </li>
        </ul>
    </div>

    <ng-container *ngIf="session && session.active">
        <button id="grant-permission" color="primary"
            [disabled]="generatingToken"
            (click)="generateToken()" mat-button mat-raised-button>
            Generate token
        </button>

        <ng-container *ngIf="token">
            <div>
                Token:
                <input matInput
                    *ngIf="token"
                    class="generated-token"
                    size="{{ token.length }}"
                    readonly="readonly"
                    [value]="token" />

            </div>
            Copy this to the web that linked you here to complete the authorization.
        </ng-container>
    </ng-container>

    <ng-container *ngIf="session && !session.active">
        You are not logged to PrograMaker. An anonymous user cannot generate an authorization.

        <br /> <br />
        <a class="login-link" routerLink="/login">Log in</a> or
        <a class="register-link" routerLink="/register">Register new user</a>
    </ng-container>
</div>
