<div class="settings-section row" *ngIf="groupInfo">
    <div class="avatar-settings-col col-sm-4">
        <div [ngClass]="'settings-group ' + (loadedImage ? 'edited' : '')">
            <div class="avatar">
                <img role="avatar" *ngIf="session" #imgPreview
                    src="{{_getGroupPicture(groupInfo.id)}}"
                    onError="this.src='/assets/profile-not-set.png'" />
            </div>
            <div class="avatar-edit-section">
                <button color="primary" class="picture-upload-button"
                    (click)="imgFileInput.click()"
                    mat-raised-button>
                    <mat-icon>edit</mat-icon> Upload {{ loadedImage ? ' new ' : '' }} picture
                </button>
                <input hidden accept="image/*" type="file" #imgFileInput (change)="previewImage($event)"/>

                <button mat-button mat-raised-button
                    #saveAvatarButton
                    class="save-button"
                    color="primary"
                    *ngIf="loadedImage"
                    (click)="saveAvatar()">
                    <span class="load-bg"></span>
                    <mat-icon class="action-icon">cloud_upload</mat-icon>
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="general-settings col-sm-8">
        <div class="user-settings settings-group">
            <div class="title">Collaborators</div>

            <div class="description">
                Update the collaborator list:
            </div>


            <app-group-collaborator-editor #groupCollaboratorEditor
                [collaborators]="this.collaborators"
            ></app-group-collaborator-editor>

            <button mat-button mat-raised-button
                class="save-button"
                color="primary"
                #saveCollaboratorsButton
                (click)="saveCollaborators()">
                <span class="load-bg"></span>
                <mat-icon class="action-icon">cloud_upload</mat-icon>
                Save
            </button>
        </div>

        <div class="admin-settings settings-group">
            <div class="title">Administration</div>
            <div class="config-option collaborator-level-for-bridge-usage">
                <div class="description">
                    Which collaborators will be able to use the group's bridges for private programs?
                </div>

                <mat-select [(value)]="minCollaboratorForPrivateBridgeUsage" [disabled]="!session">
                    <mat-select-trigger>
                        <mat-icon>{{ minCollaboratorForPrivateBridgeUsage !== 'not_allowed'
                                      ? _roleToIcon(minCollaboratorForPrivateBridgeUsage)
                                      : 'do_not_disturb' }}</mat-icon>

                        {{ group_admitted_for[minCollaboratorForPrivateBridgeUsage] }}
                    </mat-select-trigger>

                    <mat-option value="not_allowed"><mat-icon>do_not_disturb</mat-icon> {{ group_admitted_for['not_allowed'] }}</mat-option>
                    <mat-option value="admin" ><mat-icon>{{ _roleToIcon('admin') }}</mat-icon > {{ group_admitted_for['admin']  }}</mat-option>
                    <mat-option value="editor"><mat-icon>{{ _roleToIcon('editor') }}</mat-icon> {{ group_admitted_for['editor'] }}</mat-option>
                    <mat-option value="viewer"><mat-icon>{{ _roleToIcon('viewer') }}</mat-icon> {{ group_admitted_for['viewer'] }}</mat-option>
                </mat-select>
            </div>

            <button mat-button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="minCollaboratorForPrivateBridgeUsage === inServerMinCollabLevelForBridge"
                #saveAdminSettingsButton
                (click)="saveAdminSettings()">
                <span class="load-bg"></span>
                <mat-icon class="action-icon">cloud_upload</mat-icon>
                Save
            </button>
        </div>
        <div class="removal-settings settings-group">
            <div class="title">Deletion</div>
            <div class="config-option">
                <button mat-button mat-raised-button color="warn"
                    class="delete-group-button"
                    #deleteGroupButton
                    (click)="startDeleteGroup()">
                    <mat-icon>delete_forever</mat-icon> Delete group
                </button>
            </div>
        </div>
</div>
