<h2 mat-dialog-title>
    Create new template
</h2>

<mat-dialog-content class="full-dialog-size">
    <div class="col-sm-12">
        <mat-form-field class="full-size col-sm-12">
            <input matInput class="col-sm-12"
                [(ngModel)]="template.name"
                placeholder="Template name"
                name="template-name" />
        </mat-form-field>
    </div>

    <div class="col-sm-12">
        <ul class="col-sm-4 variable-list">
            <h2>Expected values</h2>
            <li *ngFor="let variable of variables" (mousedown)="userSelectedVar(variable, 'input')"
                class="variable input-variable">
                {{variable}}
            </li>
            <h2 *ngIf="!allOutputsUsed">Filled values</h2>
            <div class="container"  *ngFor="let variable of variables">
                <li *ngIf="!usedOutputs[variable]" (mousedown)="userSelectedVar(variable, 'output')"
                class="variable output-variable">
                {{variable}}
                </li>
            </div>
        </ul>
        <div matInput class="col-sm-8"
            placeholder="Template content"
            name="template-content"
            class="template-editor"
            (keypress)="onTemplateChange"
            contenteditable=true>Hello, I'm Blah blah blah</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="cancellation" mat-button [mat-dialog-close]="false">Cancel</button>
    <button class="confirmation"
        type="submit"
        color="primary"
        mat-button
        [mat-dialog-close]="true"
        [disabled]="!template.name"
        (click)="send_form()"
        mat-raised-button>Create</button>
</mat-dialog-actions>
