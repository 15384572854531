<div #container>
    <h1 id="project-name">
        <p>
            <img class="logo" src="/assets/logo-dark.png" alt="Project logo" />
        </p>
        <p>
            <span class="name">Progra&shy;Maker</span>
        </p>
    </h1>

    <div class="project-name-offer-separator"></div>

    <h2 id="project-offer">Your things, your rules</h2>

    <div class="project-offer-claim-separator"></div>

    <h3 id="project-claim">PrograMaker is an Open Source platform to easily program services and devices using visual tools.</h3>

    <div class="before-call-to-action-separator"></div>

    <div class="call-to-action-button-container">
        <button (click)="followCallToAction()"
            mat-raised-button>
            Try it !
        </button>
    </div>

    <div class="before-blocks-example-separator"></div>

    <div id="scratch-showcases" class="showcase-window grid grid-pad">
        <div class="row">
            <div class="showcase col-m-4">
                <h2>Connect devices to databases</h2>
                <img src="/assets/showcase/scratch/noise-db-block.png" alt="Save noise lecture to DB showcase" />
            </div>

            <div class="showcase col-m-6">
                <h2>Add logic to existing services</h2>
                <img src="/assets/showcase/scratch/weather-notification-block.png" alt="Schedule weather notification on week days" />
            </div>
        </div>
    </div>

    <div class="before-mail-call" *ngIf="environment.contact_mail"></div>

    <div id="contact-mail-call" *ngIf="environment.contact_mail">
        Got any doubt? Ideas? Proposals?
        Contact us on <a class="contact-mail" href="mailto:{{environment.contact_mail}}">{{environment.contact_mail}}</a>
    </div>

    <div class="before-inspirations"></div>

    <div id="inspiration-references" class="reference-window">
        <h2>Inspirations</h2>
        <ul>
            <li>
                <a href="https://ifttt.com/" class="reference-name">IFTTT</a>
                <span class="reference-description">
                    is a great tool to connect together different services in simple flows.
                    <span class="project-name">PrograMaker</span> expands on this concept to allow more flexible usage of possibilities from services and devices, and adds the possibility to add additional logic and memory to these flows. All of this without having to write traditional code.
                </span>
            </li>
        </ul>
    </div>
</div>
