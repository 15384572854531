<div *ngIf="availableBridges === null">
    <h2 mat-dialog-title></h2>
    <mat-dialog-content class="info-msg">
        Loading available connections...
    </mat-dialog-content>
</div>

<div *ngIf="(availableBridges !== null) && (availableBridges.length == 0)">
    <h2 mat-dialog-title>Connections complete</h2>
    <mat-dialog-content class="info-msg">
        <mat-icon class="text-icon">check</mat-icon> All possible connections have been established
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="cancellation" mat-button [mat-dialog-close]="false">Close</button>
    </mat-dialog-actions>
</div>

<div *ngIf="(availableBridges !== null) && (availableBridges.length > 0)">
    <h2 mat-dialog-title>Select a bridge to connect to</h2>

    <mat-dialog-content>
        <div class="collection-grid">
            <div class="grid grid-pad">
                <div class="row">

                    <div *ngFor="let bridge of availableBridges" class="col-sm-6">
                        <mat-card class="module bridge"
                            role="button"
                            (click)="enableService(bridge)">
                            <h4>{{ bridge.name }}</h4>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="cancellation" mat-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
</div>
