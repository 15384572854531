<div>
    <h2 mat-dialog-title>Connecting to {{ data.bridgeInfo.name }}</h2>

    <mat-dialog-content>
        <div [ngClass]="!(howToEnable && howToEnable.type !== 'direct') ? 'hidden' : ''" #renderingZone></div>
        <div *ngIf="howToEnable && howToEnable.type === 'direct'">
            Everything is ready to connect, just click <span class="button-ref">Accept</span>.
        </div>
        <div *ngIf="error">
            <div *ngIf="errorType === 'bridge_not_ready'">
                The bridge has not connected to Programaker yet. Connect it and retry.
            </div>
            <div *ngIf="errorType !== 'bridge_not_ready'">
                An error has happened while obtaining the information needed to connect to the bridge.
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="type == 'message'" class="form-buttons">
        <button class="rejection" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="type != 'message'" class="form-buttons">
        <button class="rejection" mat-button mat-dialog-close>Cancel</button>
        <button class="confirmation" *ngIf="ready" mat-button mat-raised-button (click)="this.establishConnection()" color="primary">Accept</button>
    </mat-dialog-actions>
</div>
