<div class="settings-section row">
    <div class="avatar-settings-col col-sm-4">
        <div [ngClass]="'settings-group ' + (loadedImage ? 'edited' : '')">
            <div class="avatar">
                <img *ngIf="session" #imgPreview
                    src="{{_getUserPicture(session.user_id)}}"
                    onError="this.src='/assets/profile-not-set.png'" />
            </div>
            <div class="avatar-edit-section">
                <button color="primary" class="picture-upload-button"
                    (click)="imgFileInput.click()"
                    mat-raised-button>
                    <mat-icon>edit</mat-icon> Upload {{ loadedImage ? ' new ' : '' }} picture
                </button>
                <input hidden accept="image/*" type="file" #imgFileInput (change)="previewImage($event)"/>

                <button mat-button mat-raised-button
                    #saveAvatarButton
                    id="avatar-save-button"
                    class="save-button"
                    color="primary"
                    *ngIf="loadedImage"
                    (click)="saveUserAvatar()">
                    <span class="load-bg"></span>
                    <mat-icon class="action-icon">cloud_upload</mat-icon>
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="general-settings col-sm-8">
        <div *ngIf="session && session.tags.is_admin"
            class="admin-settings settings-group">
            <div class="title">
                <a routerLink="/settings/admin">Admin panel</a>
            </div>
            <a routerLink="/settings/admin">Click here to open the panel</a>
        </div>

        <div class="debug-info settings-group"
            *ngIf="session && session.tags.is_advanced">
            <div class="title">Debug info</div>

            <div class="user-id setting-view">
                <label for="user-id">User ID: </label>
                <span name="user-id" class="code value">{{session.user_id}}</span>
            </div>
        </div>

        <div *ngIf="session" class="user-settings settings-group">
            <div class="title">User settings</div>

            <div class="advanced-user toggle-setting">
                <label for="advanced-user-toggle">Advanced controls</label>

                <mat-slide-toggle
                    class="value"
                    color="accent"
                    [checked]=is_advanced
                    (change)="onChangeAdvancedSettings($event)">
                    <span>{{ is_advanced ? 'Enabled' : 'Disabled' }}</span>
                </mat-slide-toggle>
            </div>

            <div *ngIf="is_advanced" class="advanced-user toggle-setting">
                <label for="in-preview-user-toggle">Preview features in Development</label>

                <mat-slide-toggle
                    class="value"
                    color="accent"
                    [checked]=is_in_preview
                    (change)="onChangeInPreviewSettings($event)">
                    <span>{{ is_in_preview ? 'Enabled' : 'Disabled' }}</span>
                </mat-slide-toggle>
            </div>

            <div class="user-profile settings-group">
                <div class="title">Profile info</div>

                <section *ngIf="groups" class="groups">
                    <div class="section-title">Public collaborations</div>

                    <div class="row" *ngIf="groups.length > 0">
                        <mat-card *ngFor="let group of groups"
                            (click)="listedGroups[group.id] = !listedGroups[group.id]"
                            role="button"
                            [ngClass]="{
                            'group': true,
                            'col-sm-2': true,
                            'listed': listedGroups[group.id],
                            'unlisted': !listedGroups[group.id]
                            }"
                        >
                            <img role="avatar" class="icon" *ngIf="group.picture" src="{{ group.picture }}" alt="{{ group.name }}"
                                matTooltip="{{ group.name }}">

                            <span class="group-name">
                                {{ group.name }}
                            </span>
                        </mat-card>
                    </div>
                    <div *ngIf="groups.length === 0">
                        You have not joined any group yet.
                    </div>
                </section>
            </div>

            <div class="save-section">
                <button mat-button mat-raised-button
                    id="user-settings-save-button"
                    class="save-button"
                    color="primary"
                    (click)="saveUserSettings()">
                    <span class="load-bg"></span>
                    <mat-icon class="action-icon">cloud_upload</mat-icon>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
