<mat-card class="login-form center-block">
    <form class="container-fluid grid grid-pad">

        <div class="error-message col-sm-12" *ngIf="!!errorMessage">
            <span{{ errorMessage }}
        </div>
        <div class="info-message col-sm-12" *ngIf="!!infoMessage">
            {{ infoMessage }}
        </div>
        <div class="info-message col-sm-12" *ngIf="(!infoMessage) && (!errorMessage)">
            Type and confirm your new password
        </div>

        <div>
            <div class="col-sm-12">
                <mat-form-field class="full-size col-sm-12">
                    <input matInput  class="col-sm-12"
                        id="registerPassword"
                        [(ngModel)]="password"
                        placeholder="Password"
                        (focusout)="validatePassword()"
                        type="password"
                        name="password" />
                </mat-form-field>
                <mat-error *ngIf="passwordErrorMessage">
		            {{ passwordErrorMessage }}
	            </mat-error>
            </div>

            <div  class="col-sm-12">
                <mat-form-field class="full-size col-sm-12">
                    <input matInput  class="col-sm-12"
                        id="registerRepeatedPassword"
                        [(ngModel)]="repeatedPassword"
                        placeholder="Repeat password"
                        (focusout)="validateRepeatedPassword()"
                        type="password"
                        name="repeatedPassword" />
                </mat-form-field>
                <mat-error *ngIf="repeatedPasswordErrorMessage">
		            {{ repeatedPasswordErrorMessage }}
	            </mat-error>
            </div>

            <br />
            <div *ngIf="status == 'validatedToken'" class="col-sm-12 accept-cancel">
                <button color="primary"
                    [disabled]="(!validPassword)
                    || (!validRepeatedPassword)
                    || (password != repeatedPassword)"
                    (click)="resetPassword()"
                    mat-button mat-raised-button>
                    Reset password
                </button>
            </div>
            <div *ngIf="status == 'validating'" class="verifying">
                <div class="centered">Verifying reset code</div>
                <mat-spinner
                    class="center-block"
                    [color]="accent">
                </mat-spinner>
            </div>
        </div>
    </form>
</mat-card>
