<h2 mat-dialog-title>
    Create custom signal
</h2>

<mat-dialog-content>
    Introduce signal name:
    <mat-form-field class="full-size col-sm-12">
        <input matInput  class="col-sm-12"
               [(ngModel)]="signal.name"
               placeholder="Signal name"
               type="text"
               name="signal_name" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="cancellation" mat-button [mat-dialog-close]="false">Cancel</button>
    <button class="confirmation"
            type="submit"
            color="primary"
            mat-button
            [mat-dialog-close]="true"
            [disabled]="!signal.name"
            mat-raised-button>Create</button>
</mat-dialog-actions>
