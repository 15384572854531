<div class="app-content">
    <mat-toolbar id="main-toolbar">
        <a routerLink="/" id="main-title"> <img class="logo" src="/assets/logo.png" aria-hidden="true" alt="Back to dashboard"> {{title}} </a>
        <span class="floating-elements">
            <span class="login-indicator">
                <a class="action-link account-menu"
                    [matMenuTriggerFor]="userDropDownMenu"
                    *ngIf="session.active">
                    <img class="account-picture"
                        src="{{ _getUserPicture(session.user_id) }}"
                        aria-hidden="true"
                        alt="User profile picture"
                        onError="this.src='/assets/profile-not-set.png'" />
                </a>
                <div class="login-button" *ngIf="!session.active">
                    <a class="action-link" routerLink="/login">Login</a>
                </div>
            </span>

            <mat-menu class="link-menu" #userDropDownMenu="matMenu">
                <a mat-menu-item routerLink="/users/{{ session.username }}">
                    <mat-icon>account_circle</mat-icon>
                    User profile
                </a>
                <a mat-menu-item routerLink="/dashboard">
                    <mat-icon>dashboard</mat-icon>
                    Dashboard
                </a>
                <a mat-menu-item routerLink="/settings">
                    <mat-icon>settings</mat-icon>
                    Settings
                </a>
                <hr/>
                <a mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    Logout
                </a>
            </mat-menu>
        </span>
    </mat-toolbar>
    <div class="viewer" id="app-content">
        <router-outlet></router-outlet>
    </div>
</div>
