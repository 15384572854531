<h2 mat-dialog-title>Set <span class="program-name">{{ program_name }}</span> tags</h2>

<mat-dialog-content>
    <mat-form-field class="tag-list">
        <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip
                *ngFor="let tag of tags"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
                #tagInput
                [formControl]="tagCtrl"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
        </mat-chip-list>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="cancellation" mat-button [mat-dialog-close]="false">Cancel</button>
    <button class="confirmation"
            type="submit"
            color="primary"
            mat-button
            [mat-dialog-close]="true"
            mat-raised-button>Update</button>
</mat-dialog-actions>
